// ./src/pages/blog.js
import { graphql } from "gatsby"
import React, { useState, useEffect } from "react"
import { navigate, injectIntl } from "gatsby-plugin-intl"
import { groupBy, orderBy } from "lodash"
import Helmet from "react-helmet"

import { localize } from "../utils"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Tabs from "../components/tabs"
import Image from "../components/image"
import ProductLeaf from "../components/product-leaf"
import TopBar from "../components/topbar"
import SectionChar from "../components/section-char"
import SectionVideo from "../components/section-video"

// The query used to provide the page data
export const pageQuery = graphql`
  query GatsbyCategoriesPageQuery($productLineSlug: String) {
    allDirectusBlock(filter: { page: { in: ["prodotti", "linea"] } }) {
      nodes {
        page
        order
        section
        type
        data {
          body
          title
          lang
        }
        images {
          title
          private_hash
          localFile {
            childImageSharp {
              fixed(width: 1024, webpQuality: 100) {
                srcSet
                srcSetWebp
              }
            }
          }
        }
      }
    }
    allDirectusCategory {
      nodes {
        main_product {
          image {
            private_hash
            title
            localFile {
              childImageSharp {
                fixed(height: 260) {
                  srcSet
                  srcSetWebp
                }
              }
            }
          }
        }
        product_line {
          directusId
          simple
          topbar_images
          image {
            private_hash
            title
            localFile {
              childImageSharp {
                fixed(height: 260) {
                  srcSet
                  srcSetWebp
                }
              }
            }
          }
          data {
            name
            description
            full_description
            slug
            lang
          }
        }
        data {
          name
          slug
          lang
        }
        directusId
      }
    }
    allDirectusProduct(
      filter: {
        category: {
          product_line: {
            data: { elemMatch: { slug: { eq: $productLineSlug } } }
          }
        }
      }
    ) {
      nodes {
        item_id
        id
        hidden
        category {
          directusId
        }
        order
        data {
          name
          short_description
          slug
          lang
        }
        image {
          title
          private_hash
          localFile {
            childImageSharp {
              fixed(height: 260) {
                srcSet
                srcSetWebp
              }
            }
          }
        }
      }
    }
  }
`

const Categories = ({ data, intl, location }) => {
  const {
    allDirectusCategory: { nodes: categories },
    allDirectusProduct: { nodes: productsInpage },
    allDirectusBlock: { nodes: blocks },
  } = data

  const localizedPL = categories.map(category => {
    const product_line = localize(category.product_line, intl.locale)
    category.productLine = product_line
    return localize(category, intl.locale)
  })
  const productLines = groupBy(localizedPL, "productLine.slug")
  const slugs = Object.keys(productLines)

  const localizedPIP = productsInpage.map(product => {
    return localize(product, intl.locale)
  })

  const localizedBlocks = blocks.map(blockData =>
    localize(blockData, intl.locale)
  )

  const [activeTab, setActiveTab] = useState(slugs[0])
  const [tabEntries, setTabEntries] = useState([])
  useEffect(() => {
    console.log(location.href)
    if (location.href && location.href.includes("/prodotti")) {
      const urlTab = location.href.split(/\/|#/g).pop().split('?')[0];
      console.log('tab', urlTab)
      setActiveTab(urlTab || slugs[0])
    } else setActiveTab(slugs[0])

    const tabEntries = slugs.map(slug => {
      return {
        id: slug,
        name: productLines[slug][0].productLine.name,
      }
    })
    setTabEntries(tabEntries)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    console.log(location.href)
    if (location.href && location.href.includes("/prodotti")) {
      const urlTab = location.href.split(/\/|#/g).pop().split('?')[0];
      console.log('tab', urlTab)
      setActiveTab(urlTab || slugs[0])
    } else setActiveTab(slugs[0])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  let currentCategories = productLines[activeTab]
  if (!currentCategories) {
    // gestisce cambio lingua
    currentCategories = productLines[Object.keys(productLines)[0]]
    setActiveTab(slugs[0])
  }
  const currentProductLine = currentCategories[0].productLine

  const productLineBlock = localizedBlocks.find(
    block => block.page === "prodotti"
  )
  const productLineSections = orderBy(
    localizedBlocks.filter(
      block =>
        block.page === "linea" &&
        parseInt(block.section) === currentProductLine.directusId
    ),
    "order"
  )

  const currentProducts = localizedPIP.filter(
    product =>
      product.category &&
      product.category.directusId === currentCategories[0].directusId &&
      !product.related_to &&
      !product.hidden
  )

  if (!currentCategories) return null
  return (
    <Layout name="p-products">
      <Helmet
        bodyAttributes={{
          class: "body-for-sticky",
        }}
      />
      <Seo title={currentProductLine.name} />
      <TopBar title={productLineBlock.title} small>
        {currentProductLine.topbar_images && <div className="p-products__header__image">
          {!currentProductLine.simple
            ? currentCategories.slice(0, 2).map(category => {
                return (
                  <div className="p-products__header__image__item" key={category.slug}>
                    <Image
                      image={
                        category.main_product && category.main_product.image
                      }
                    />
                  </div>
                )
              })
            : currentProducts.slice(0, 2).map(product => {
                // here bad things with css - margin to 0 due to product images
                return (
                  <div
                    className="p-products__header__image__item"
                    style={{ marginBottom: "0px" }}
                  >
                    <Image image={product.image} />
                  </div>
                )
              })}
        </div>}
      </TopBar>
      <Tabs
        tabEntries={tabEntries}
        activeTab={activeTab}
        onTabChange={slug => {
          navigate(`/prodotti/${slug}`)
        }}
      />

      <div key={activeTab} className="a-container p-products__tab">
        <div className="p-products__tab-header">
          <div className="p-products__title">
            <h4>{currentProductLine.name}</h4>
            {currentCategories.length > 1 && (
              <span>
                {`${currentCategories.length} ${intl.formatMessage({
                  id: "terms.categories",
                })}`}
              </span>
            )}
          </div>
          <div className="p-products__abstract">
            {currentProductLine.full_description}
          </div>
        </div>
        {productLineSections.map(section => {
          if (section.type === "video") return <SectionVideo key={section.order} section={section} />
          else return <SectionChar key={section.order} section={section} />
        })}
        {!currentProductLine.simple ? (
          <div className="p-products__categories">
            {currentCategories.map(category => {
              return (
                <div key={category.name} className="p-products__category">
                  <ProductLeaf
                    onClick={() =>
                      navigate(
                        `/prodotti/${category.productLine.slug}/${category.slug}`
                      )
                    }
                    options={{ compact: true }}
                    item={{
                      name: category.name,
                      slug: category.slug,
                      image:
                        category.main_product && category.main_product.image,
                    }}
                  />
                </div>
              )
            })}
          </div>
        ) : (
          <div className="p-products__categories">
            {orderBy(currentProducts, "order").map(product => {
              return (
                <div className="p-products__category">
                  <ProductLeaf
                    onClick={() =>
                      navigate(`/prodotto/${product.item_id}`)
                    }
                    options={{ compact: true }}
                    item={{
                      ...product,
                      name: product.name,
                      slug: product.slug,
                      description: product.short_description,
                      image: product.image,
                    }}
                  />
                </div>
              )
            })}
          </div>
        )}
      </div>
    </Layout>
  )
}

export default injectIntl(Categories)
